.ant-form {

  .ant-form-item-label {
    line-height: 1.6rem;
    label {
      font-size: 1.5rem;
      line-height: 1.6rem;
      font-weight: 600;
      &:before,
      &:after {
        display: none;
      }
    }
  }

  .ant-form-item-control-wrapper {
    margin-top: .8rem;
    .ant-form-item-control {

      .ant-form-item-children {
        input {
          background-color: map_get($colors, white);
          border-color: transparent;
          padding: .5rem 1.1rem;
          height: 40px;
        }
        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus {
          -webkit-text-fill-color: map_get($colors, main);
          -webkit-box-shadow: 0 0 0px 1000px #fff inset;
          transition: background-color 5000s ease-in-out 0s;
        }
      }
    }
  }
}