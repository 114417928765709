@import '~normalize.css';
@import 'variable.scss';
@import 'form.scss';
//@import 'typo.scss';

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;

  font-family: 'Source Sans Pro';
  font-weight: normal;
  color: map_get($colors, main);
  background: map_get($colors, white);

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  text-decoration: none;
}

main {
  position: relative;
  min-height: 100vh;
}
