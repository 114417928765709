@import "../../styles/variable";
.footer {
  //position: absolute;
  //left: 0;
  //bottom: 0;
  width: 100%;
  padding: 2rem;
  display: flex;
  justify-content: center;

  .footer__link {
    color: map_get($colors, dusty_gray);
    padding: 0 1rem;
    &:hover {
      color: map_get($colors, dove_gray);
      text-decoration: underline;
    }
  }
}